<template>
  <div class="VIPclassxq">
    <!-- 顶部banner图 -->
    <div class="bannerWrap">
      <div class="bigBlock">
        <div class="itroductionWrap">
          <div class="itroductionLeft">
            <div class="crumbs">
              首页>VIP专属班>{{ detalilsList ? detalilsList.tiitle : '' }}
            </div>
            <div class="itroductionLeftImg">
              <div v-if="detalilsList.trialVideoConfig && jgLogo" class="jgLogo">
                <img :src="jgLogo" alt="">
              </div>
              <!-- <video
                v-if="
                  detalilsList.trialVideoConfig &&
                  detalilsList.trialVideoConfig.endsWith('mp4')
                "
                oncontextmenu="return false;"
                controlslist="nodownload noremoteplayback nofullscreen"
                disablePictureInPicture
                width="658px"
                height="370px"
                :playbackRates="[0.5, 1, 1.5, 2]"
                controls
              >
                <source :src="detalilsList.trialVideoConfig" type="video/mp4" />
              </video> -->
              <jxyplayer v-if="detalilsList.trialVideoConfig &&
                detalilsList.trialVideoConfig.endsWith('mp4')
                " :url="detalilsList.trialVideoConfig" :access-key-id="accessKeyId" :logo-url="jgLogo"
                :access-key-secret="accessKeySecret" :options="option" />
              <jxyplayer v-else-if="detalilsList.trialVideoConfig &&
                accessKeyId &&
                accessKeySecret
                " :url="detalilsList.trialVideoConfig" :access-key-id="accessKeyId" :logo-url="jgLogo"
                :access-key-secret="accessKeySecret" :options="option" />
              <img v-else :src="detalilsList ? detalilsList.img : ''">
            </div>
            <div />
          </div>
          <div class="itroductionRight">
            <div class="itroductionTitle">
              {{ detalilsList.tiitle ? detalilsList.tiitle : '' }}
            </div>
            <!-- <div class="label-12">直播回放/上课提示/虚拟考场/章节练习/随堂练习/电子讲义/8小时极速答疑</div> -->
            <!-- <div class="link-14">
              <img src="@/assets/img/Home/Group2863.png" />
              <span>五星级专属服务，购后立享>></span>
            </div> -->
            <div class="priceBlock">
              <div class="priceWrap">
                <span class="label">价格</span>
                <span class="symbol">￥</span>
                <span class="price">{{
                  directionPrice ? directionPrice : detalilsList.price
                }}</span>
              </div>
              <!-- <div class="priceWrap1">原价 {{ detalilsList ? detalilsList.olderPrice : '' }}</div> -->
            </div>
            <div class="label-12 teacherMain">
              <span class="label-12-Title">主讲老师</span>
              <span class="validPeriod">{{ teacherName }}</span>
            </div>
            <div class="label-12">
              <span class="label-12-Title">特色服务</span>
              <span class="validPeriod">直播回放 | 上课提示 | 虚拟考场 | 章节练习 | 电子讲义 | 随堂练习
                | 急速答疑</span>
            </div>
            <div class="label-12">
              <span class="label-12-Title">课程时长</span>
              <span class="validPeriod">{{ `共${detalilsList ? detalilsList.allTime : '0'}课时` }}
              </span>
            </div>
            <div class="label-12">
              <span class="label-12-Title">有效期</span>
              <span class="validPeriod label-12_ml1">{{
                detalilsList.validStartTime
                ? `${detalilsList.validStartTime} - ${detalilsList.validEndTime}`
                : `${detalilsList.validity ? detalilsList.validity : `0`}年`
              }}
              </span>
            </div>
            <!-- <hr /> -->
            <!-- <div class="label-12">
              <span class="label-12-Title">
                <span style="margin-right: 2em">专</span>
                <span>属</span>
              </span>
              <span class="label-12-Select">专属资料,专属题库,专属教材,专属考场,专属教服-8小时服务</span>
            </div> -->
            <!-- <div class="label-12">
              <span class="label-12-Title">课程时间</span>
              <span class="validPeriod">{{ `${detalilsList ? detalilsList.validity : '0'}年` }}</span>
            </div> -->
            <div v-if="packageList.length" class="label-14">
              <span style="margin-right: 24px" class="label-12-Title">方</span>
              <span style="margin-right: 22px" class="label-12-Title">向</span>
              <el-select v-if="packageList.length" v-model="direction" class="label-12-rgba" clearable size="mini"
                placeholder="选择方向" @change="changeSelect">
                <el-option v-for="item in packageList" v-show="item.isShow != 1" :key="item.id" :label="item.name"
                  :value="item.id" />
              </el-select>
            </div>
            <div class="btnWrap">
              <!-- <div class="consult">咨询老师</div> -->
              <div v-if="!userInfo || !IsPurchase" class="shop" @click="zhifu">
                立即购买
              </div>
              <div v-else class="shop">已购买</div>
              <div class="icon-div">
                <img v-if="issc" class="img-vs" src="@/assets/img/Home/z8.png" object-fit="contain"
                  @click="collectProduct()">
                <img v-if="!issc" class="img-vs" src="@/assets/img/Home/z7.png" object-fit="contain"
                  @click="collectProduct()">
                收藏
              </div>
            </div>
            <div v-if="!userInfo || !IsPurchase" class="hint">
              <!-- 温馨提示 · 支持7天无理由退货 -->
            </div>
          </div>
        </div>
        <div class="fiveBlock">
          <div class="blockItem" @click="openTextBook">
            <img src="@/assets/img/Home/jc.png">
            <span>专属教辅</span>
          </div>
          <div class="blockItem" @click="openMaterial">
            <img src="@/assets/img/Home/zl.png">
            <span>专属资料</span>
          </div>
          <div class="blockItem" @click="openQuestionBank">
            <img src="@/assets/img/Home/tk.png">
            <span>专属题库</span>
          </div>
          <div class="blockItem" @click="openRoom">
            <img src="@/assets/img/Home/kc.png">
            <span>专属考场</span>
          </div>
          <div class="blockItem" @click="openEducate">
            <img src="@/assets/img/Home/jf.png">
            <span>专属教服</span>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-title">
      <template v-for="(item, index) in tabList">
        <div v-if="item.show" :key="index" class="item">
          <div class="title-text" :class="navIndex == item.type ? 'active' : ''" @click="navs(item.type)">
            {{ item.text }}
          </div>
          <div v-if="navIndex == item.type" class="on" />
        </div>
      </template>
    </div>
    <div class="nav-content">
      <!-- <div class=""></div> -->
      <!-- 选项栏 -->

      <!-- 知识套餐概述内容 -->
      <div v-if="navIndex == 1" class="warpCon warpCons">
        <div v-if="detalilsList.content" class="appleLefts" v-html="ImgSizeLimit(detalilsList.content)" />
        <div v-if="!detalilsList || !detalilsList.content" class="nothing">
          <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
          <div class="nothing-text">暂无内容哦~</div>
        </div>
      </div>
      <!-- 课表 -->
      <div v-if="navIndex == 2" class="warpCon">
        <couser-view :is-purchase="IsPurchase" :product-line="detalilsList.productLine" :is-freeze="detalilsList.isFreeze"
          :classes-id="id" :card-code="cardCode" />
      </div>

      <!-- 课程评价 -->
      <div v-if="navIndex == 7" class="warpCon">
        <score-evaluation v-if="navIndex == 7" :type="1" :type-id="id" :class-id="id" :is-purchase="IsPurchase"
          :type-nmae="detalilsList.tiitle" :product-line="detalilsList.productLine" />
      </div>
      <!-- 专属资料 -->
      <div v-if="navIndex == 5" class="warpCon">
        <data-view :is-purchase="IsPurchase" :direction="direction" :product-line="detalilsList.productLine"
          :classes-id="id" />
      </div>
      <!-- 专属题库 -->
      <div v-if="navIndex == 6" class="warpCon">
        <exam-views :is-purchase="IsPurchase" :product-line="detalilsList.productLine" :classes-id="id"
          :card-code="cardCode" />
      </div>
      <you-love v-if="id && kind && navIndex == 1" :id="id" :kind="kind" class="youlove" :ifteacher="false"
        :type="1">推荐套餐</you-love>
    </div>
    <!-- 专属教服弹窗 -->
    <educate-dialog ref="educateDialog" :direction="direction" :educate-visible="educateVisible"
      @educateClose="educateClose" />
    <!-- 专属教材弹窗 -->
    <text-book-dialog ref="textBookDialog" :direction="direction" :book-visible="bookVisible" :is-purchase="IsPurchase"
      @bookClose="bookClose" />
    <!-- 专属考场弹窗 -->
    <el-dialog title="专属考场" :visible.sync="roomVisible" width="660px">
      <room-dialog v-if="roomVisible" ref="roomDialog" :direction="direction" :classes-id="id"
        :is-purchase="IsPurchase" />
    </el-dialog>

    <!-- 专属资料 -->
    <material ref="material" :material-visible="materialVisible" :classes-id="id" :direction="direction"
      @materialClose="materialClose" />
    <!-- 专属题库 -->
    <!--   <question-bank
      ref="questionBank"
      :questionBankVisible="questionBankVisible"
      @questionBankClose="questionBankClose"
    ></question-bank> -->
    <el-dialog title="专属题库" :visible.sync="questionBankVisible" width="660px">
      <classes-question :classes-id="id" :is-purchase="IsPurchase" />
      <!-- <exam-view
        :direction="direction"
        v-if="questionBankVisible"
        :IsPurchase="IsPurchase"
        :productLine="3"
        :classesId="id"
      /> -->
    </el-dialog>

    <div v-if="isFreeze == 1" class="mask_layer">课程已冻结，请联系老师</div>
  </div>
</template>

<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { liveEnum } from '@/api/emun'
import educateDialog from './dialog/educateDialog.vue'
import textBookDialog from './dialog/textBookDialog.vue'
import couserView from '@/components/Know/classCom/couserViewnew.vue'
import couserViewPast from '@/components/Know/classCom/couserViewnewPast.vue'
import classesQuestion from '@/components/Know/classCom/classes-question.vue'
import roomDialog from './dialog/roomDialog.vue'
import jxyplayer from '@/components/jxyPlay.vue'
import material from './dialog/materialDialog.vue'
import {
  getClassDetailsVip,
  getClass,
  checkStudyCode,
  getClassCoursePackageVip
} from '@/api/home.js'
import { getInfo, getToken, getDomain } from '@/api/cookies'
import scoreEvaluation from '@/components/Know/course/scoreEvaluationnew.vue'
import examView from '@/components/Know/classCom/examView.vue'
import examViews from '@/components/Know/classCom/examViewVip.vue'
import dataView from '@/components/Know/classCom/dataViewVip.vue'
import { getAccessKey } from '@/api/home'
import YouLove from '@/components/Know/youLove.vue'
import { Know } from '@/api/know'
const know = new Know()

export default {
  components: {
    educateDialog,
    jxyplayer,
    textBookDialog,
    roomDialog,
    material,
    examView,
    couserView,
    couserViewPast,
    scoreEvaluation,
    YouLove,
    dataView,
    examViews,
    classesQuestion
  },
  data() {
    return {
      liveEnum: liveEnum,
      direction: '',
      id: null, // 班型ID
      educateVisible: false, // 专属教服显隐
      bookVisible: false, // 专属教材显隐
      roomVisible: false, // 专属考场显隐
      materialVisible: false, // 专属资料显隐
      questionBankVisible: false, // 专属题库显隐
      detalilsCourseList: [], // 专属详情课程
      detalilsList: {}, // 详情
      info: 0,
      kind: 0,
      playbackRate: 1,
      IsPurchase: null, // 是否购买
      userInfo: null,
      extendJosn: {},
      option: {
        width: '100%',
        height: '100%',
        type: 'HLS',
        aDType: 'swiper'
      },
      accessKeySecret: null,
      accessKeyId: null,
      directionPrice: null,
      packageList: [],
      smallList: [],
      cardCode: null, // 学习卡id
      isFreeze: 0,
      teacherName: '',
      tabList: [
        { text: '班型介绍', type: 1, show: true },
        { text: '班型课表', type: 2, show: true },
        { text: '课程资料', type: 5, show: false },
        { text: '课程测试', type: 6, show: false },
        { text: '班型评价', type: 7, show: true }
        // { text: "往期课程", type: 3, show: false },
      ],
      navIndex: 1,
      loading: this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      }),
      jgLogo: null,
      haveBought: [],
      issc: false
    }
  },

  async created() {
    this.cardCode = this.$route.query.cardCode
    await this.checkStudyCode()
    this.id = await this.$route.query.id
    this.userInfo = localStorage.getItem('userId')
    if (this.userInfo) {
      this.getClassCoursePackageVip()
    }
    this.jgLogo = getDomain().videoLogo
    await this.getClass()
    await this.getAccessKey()
    await this.getDetails()
    await know.updateCourseAllClicks(1, this.id)
    await this.getCoursePackageList()
    this.getCollectProductStatus()
    this.getPastPrivateClassList()
    this.classesDocKcTableButton()
    this.classesContactTableButton()
  },
  methods: {
    async getAccessKey() {
      const res = await getAccessKey()
      if (res.code == 0) {
        this.accessKey = res.data
        this.accessKeyId = res.data.accessKeyId
        this.accessKeySecret = res.data.accessKeySecret
      }
    },
    async getPastPrivateClassList() {
      const res = await know.getPastPrivateClassList(
        this.id,
        this.userInfo ? this.userInfo.id : undefined
      )
      if (res.code == 0) {
        this.smallList = res.data
        if (this.smallList.length > 0) {
          this.tabList[5].show = true
        }
      }
    },

    collectProduct() {
      if (!this.userInfo) {
        this.$notify({
          title: '错误',
          message: '未登录, 请先去登录',
          type: 'error',
          duration: 2000
        })
        return
      }
      know
        .collectProduct({
          type: 1,
          typeId: this.id,
          stuId: this.userInfo,
          status: this.issc ? 0 : 1
        })
        .then((res) => {
          if (res.code == 0) {
            this.getCollectProductStatus()
          }
        })
    },
    getCollectProductStatus() {
      know
        .getCollectProductStatus({
          type: 1,
          typeId: this.id,
          stuId: this.userInfo
        })
        .then((res) => {
          if (res.code == 0) {
            this.issc = res.data
          }
        })
    },
    // 知识套餐课程资料年份（优化） 按钮显示
    async classesDocKcTableButton() {
      const { data } = await know.classesDocKcTableButton({
        classesId: this.id,
        coursePackageId: this.direction,
        userid: this.userInfo
      })
      if (data) {
        this.tabList[2].show = true
      }
    },
    // 知识套餐课程测试年份（优化） 按钮显示
    async classesContactTableButton() {
      const { data } = await know.classesContactTableButton({
        classesId: this.id,
        coursePackageId: this.direction,
        userid: this.userInfo
      })
      if (data) {
        this.tabList[3].show = true
      }
    },
    // 选择方向
    changeSelect(e) {

      this.packageList.forEach((element) => {
        if (e == element.id) this.directionPrice = element.price
      })
      this.classesDocKcTableButton()
    },
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode
        }
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true
            this.$store.commit('IS_PURCHASE_CHANGE', true)
          }
        })
      }
    },
    getCoursePackageList() {
      know.getCoursePackageList(this.id, 999, 1).then((res) => {
        if (this.haveBought.length) {

          const arr = []
          this.haveBought.forEach((item) => {
            const obj = res.rows.find((i) => {
              return i.id == item
            })
            arr.push(obj)
          })
          this.packageList = arr
        } else {

          this.packageList = res.rows
        }
      })
    },
    // 点击立即报名按钮 跳转支付页
    zhifu() {
      const token = getToken()
      if (token) {
        this.$router.push(
          `/orders/classOrder?classId=${this.id}&direction=${this.direction}`
        )
      } else {
        Vue.prototype.goLoginView(true)
      }
    },
    /* 点击查看详情 */
    seeDetails(item) {
      if (this.detalilsList.isFreeze == 1) {
        this.$message({
          type: 'error',
          message: '课程已冻结'
        })
        return
      }

      if (item.type == 1) {
        /* 直播 */
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}&classId=${this.id}&classProductLine=3&cardCode=${this.cardCode}`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 2) {
        /* 录播 */
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}&classId=${this.id}&classProductLine=3&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 3) {
        /* 音频 */
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}&classId=${this.id}&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 4) {
        /* 图文 */
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}&classId=${this.id}&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 5) {
        /* 面授 */
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}&classId=${this.id}&cardCode=${this.cardCode}`
        })
        window.open(routeUrl.href, '_blank')
      }
    },
    /* 打开教服弹窗 */
    openEducate() {
      this.educateVisible = true
      this.$refs.educateDialog.init(
        this.detalilsList.jfServer,
        this.detalilsList
      )
    },
    /* 关闭教服弹窗 */
    educateClose() {
      this.educateVisible = false
    },
    /* 打开教材弹窗 */
    openTextBook() {
      this.bookVisible = true
      this.$refs.textBookDialog.init(
        this.id,
        this.IsPurchase,
        this.detalilsList.tiitle
      )
    },
    /* 关闭教材弹窗 */
    bookClose() {
      this.bookVisible = false
    },
    /* 打开考场弹窗 */
    openRoom() {
      this.roomVisible = true
      // this.$refs.roomDialog.init(this.id, this.IsPurchase);
    },
    /* 关闭考场弹窗 */
    roomClose() {
      this.roomVisible = false
    },
    /* 打开专属资料 */
    openMaterial() {
      this.materialVisible = true
      this.$refs['material'].init(this.id, this.IsPurchase)
    },
    /* 关闭专属资料 */
    materialClose() {
      this.materialVisible = false
    },
    /* 打开专属题库 */
    openQuestionBank() {
      this.questionBankVisible = true
      // this.$refs.questionBank.init(this.id, this.IsPurchase);
    },
    /* 关闭专属题库 */
    questionBankClose() {
      this.questionBankVisible = false
    },
    /* 详情 */
    getDetails() {
      getClass(this.id).then((res) => {
        this.isFreeze = res.data.isFreeze
        this.detalilsList = JSON.parse(JSON.stringify(res.data.BX))

        if (this.detalilsList.trialVideoConfig && this.detalilsList.trialVideoConfig.endsWith('mp4')) {
          this.option.type = 'MP4'
        }
        if (!this.cardCode) {
          this.IsPurchase = res.data.IsPurchase
          this.detalilsList.isFreeze = res.data.isFreeze
        }
        this.kind = res.data.BX.kind
        this.extendJosn = res.data.BX.extendJosn
          ? JSON.parse(res.data.BX.extendJosn)
          : {}
      })
    },
    /* VIP详情课程 */
    async getClass() {
      await getClassDetailsVip(this.id).then((res) => {
        if (res.code == 0) {
          this.detalilsCourseList = JSON.parse(JSON.stringify(res.msg))
          this.detalilsCourseList.map((item) => {
            item.info = 0
          })
          if (res.data) {
            this.teacherName = Array.from(new Set(res.data.split(',')))
            this.teacherName = this.teacherName.join()
          }
          // this.teacherName = res.data
        }
      })
      this.loading.close()
    },
    /* 选择年份筛选 */
    currentYear(index, item, fitem) {
      fitem.info = index
      /* fitem.projectList = item.classesCourseKindVipDtoVos */
      this.$forceUpdate()
    },
    // 标签页切换
    navs(val) {
      this.navIndex = val
    },

    getClassCoursePackageVip() {
      const data = {
        id: this.id,
        userid: this.userInfo
      }
      getClassCoursePackageVip(data).then((res) => {
        if (res.code == 0) {
          this.haveBought = res.data
        }
      })
    },

    getPackageVip() {
      const data = {
        id: this.id,
        userid: this.userInfo
      }
      getClassCoursePackageVip(data).then((res) => {
        if (res.code == 0) {
          this.haveBought = res.data
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.VIPclassxq {
  .bannerWrap {
    width: 100%;
    // height: 628px;
    background-image: url("../../assets/img/Home/vipClassBanner.png");
    background-size: cover;
    padding-top: 24px;
    // margin-bottom: 60px;
    padding-bottom: 40px;

    .bigBlock {
      width: 1200px;
      margin: auto;
      background: #4c4a47;
      padding-bottom: 25px;
      border-radius: 8px;

      .itroductionWrap {
        padding: 0 24px;

        .elselect {
          background: #4c4a47;
        }

        // height: 470px;
        display: flex;
        border-radius: 8px;

        .itroductionLeft {
          margin-right: 26px;

          .itroductionLeftImg {
            margin-top: 20px;
            width: 658px;
            height: 370px;
            border-radius: 8px;
            position: relative;

            img {
              width: 100%;
              height: 100%;
              border-radius: 8px;
            }
          }

          .crumbs {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #d8c7b8;
            margin-top: 20px;
          }
        }

        .itroductionRight {
          position: relative;

          .itroductionTitle {
            font-size: 18px;
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #f6f6f6;
            line-height: 24px;
            margin-top: 56px;
          }

          .label-12 {
            font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
            font-weight: bold;
            color: #aeaeae;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.4);
            margin-top: 12px;
            margin-bottom: 14px;

            .validPeriod {
              font-family: MicrosoftYaHei;
              font-size: 12px;
              color: #f3f3f3;
              font-weight: 400;
              line-height: 18px;
            }

            .label-12-Title {
              margin-right: 22px;
              flex-shrink: 0;
            }

            .label-12-rgba {
              margin-right: 22px;
              flex-shrink: 0;
              color: rgba(255, 255, 255, 0.4);
            }
          }

          .label-14 {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.4);

            // .el-select {
            //   width: 213px !important;
            //   height: 34px;
            //   border-radius: 4px;
            //   border: 1px solid #979797;
            // }
          }

          .btnWrap {
            position: absolute;
            // margin-top: 14px;
            bottom: 0;
            display: flex;

            .consult {
              width: 120px;
              height: 40px;
              border-radius: 4px;
              text-align: center;
              line-height: 40px;
              border: 1px solid white;
              color: white;
              margin-right: 16px;
              cursor: pointer;
            }

            .shop {
              width: 168px;
              height: 45px;
              background: linear-gradient(87deg, #d6926a 0%, #a16546 100%);
              border-radius: 4px;
              border: 1px solid #ffffff;
              text-align: center;
              line-height: 45px;
              cursor: pointer;
              font-size: 16px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
            }
          }

          .label-14 /deep/ .el-input__inner {
            width: 213px;
            height: 34px;
            border-radius: 4px;
            border: 1px solid #979797;
            background-color: rgba(0, 0, 0, 0);
            color: #ffffff;
          }

          .link-14 {
            font-size: 14px;
            color: #ffffff;
            display: flex;
            align-items: center;
            margin-bottom: 21px;
          }

          .priceBlock {
            width: 468px;
            height: 60px;
            background-image: url("~@/assets/img/Home/wan_vip.png");
            background-size: cover;
            border-radius: 4px;
            // border: 1px solid #d79f75;
            line-height: 60px;
            display: flex;
            align-items: center;
            margin-top: 21px;

            .priceWrap {
              margin-left: 12px;
              // margin-right: 127px;
              display: flex;

              .label {
                font-size: 12px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #7e756d;
                color: #666666;
                font-family: Microsoft YaHei-Regular;
                margin-top: 5px;
              }

              .symbol {
                font-size: 30px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #ff6051;
                margin-left: 13px;
              }

              .price {
                font-size: 18px;
                font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
                font-weight: bold;
                color: #ff6051;
                font-size: 30px;
              }
            }

            .labelWrap {
              width: 140px;
              height: 36px;
              background-image: url("../..//assets/img/Home/Subtract.png");
            }

            .receiveBtn {
              width: 54px;
              height: 36px;
              background-image: url("../..//assets/img/Home/receiveBtn.png");
              font-size: 12px;
              color: #9a8257;
              line-height: 36px;
              text-align: center;
            }
          }

          .priceWrap1 {
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #7e756d;
            margin-left: 56px;
          }

          .hint {
            margin-top: 13px;
            color: rgba(255, 255, 255, 0.4);
            font-size: 12px;
          }
        }
      }

      .fiveBlock {
        width: 1046px;
        display: flex;
        margin: 0 auto;
        justify-content: space-between;
        // margin-bottom: 30px;
        margin-top: 32px;

        .blockItem {
          width: 180px;
          height: 60px;
          border-radius: 6px;
          background-image: url("../../assets/img/Home/wan_vip1.png");
          background-size: cover;
          display: flex;
          align-items: center;
          cursor: pointer;

          img {
            margin: 6px 17px;
            width: 48px;
            height: 48px;
            display: inline-block;
          }

          span {
            font-size: 18px;
            font-family: MicrosoftYaHei;
            color: #782201;
          }
        }
      }
    }
  }
}

.mainContentBox ::v-deep .el-carousel__arrow {
  width: 24px !important;
  height: 40px !important;
  border-radius: 4px !important;
}

.mainContentBox ::v-deep .el-carousel__arrow--left {
  left: 0;
}

.mainContentBox ::v-deep .el-carousel__arrow--right {
  right: 0;
}

.on {
  background-color: #f5f6f6;
  color: #ff8839;
}

.mask_layer {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 999999;
  position: fixed;
  background-color: #000000;
  opacity: 0.6;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.teacherMain {
  display: flex;
  margin-top: 14px !important;
}

hr {
  height: 1px;
  border: 1px solid #786e67;
  margin-top: 22px;
  margin-bottom: 20px;
}

.label-12_ml1 {
  margin-left: 1em;
}

.nav-content {
  width: 1200px;
  margin: 0 auto;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.tabs-title {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  height: 57px;
  line-height: 57px;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 23px;

  .item {
    position: relative;
    cursor: pointer;
    font-size: 19px;
    font-family: MicrosoftYaHei;
    color: #444444;
    margin-right: 60px;
  }

  .active {
    color: #ff5e51;
  }

  .on {
    position: absolute;
    bottom: 0px;
    left: calc(50% - 16px);
    width: 20px;
    height: 4px;
    background: #ff5e51;
  }
}

.warpCon {
  width: 1200px;
  // width: 860px;
  background: #fff;
  border-radius: 6px;
}

.warpCons {
  width: 860px;
}

::v-deep .appleLefts {
  img {
    margin: 20px auto;
  }
}

video::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.jgLogo {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 30px;

  img {
    display: block;
    height: 30px;
    width: auto !important;
  }
}

.icon-div {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-left: 30px;
}

.img-vs {
  cursor: pointer;
  margin-right: 5px;
}
</style>
