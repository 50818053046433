<template>
  <div class="couserView">
    <!-- <div v-if="yearKc.length > 0 && navIndex == 3" class="year_wrap">
      <div
        v-for="(item, index) in yearKc"
        :key="index"
        class="year_item"
        :class="currentYear == item.year ? 'on' : ''"
        @click="changeYear(item)"
      >
        {{ item.year }}年
      </div>
    </div> -->
    <div class="xiaoban" style="margin-bottom: 20px; padding-bottom: 20px">
      <div v-if="smallList.length > 0" class="couserView_title2">
        <span>小班课</span>
        <div class="line" />
        <div>{{ smallList[0].name }}</div>
      </div>
      <div v-for="(items, index) in smallList" :key="index">
        <chater :id="items.id" class="chater" :if-class="true" :class-id="classesId"
          :cc-config="items ? items.ccConfig : ''" :purchase="IsPurchase" :is-purchase="IsPurchase" :list="items.sections"
          :class-product-line="productLine" :card-code="cardCode" :is-freeze="isFreeze" />
      </div>
    </div>
    <div v-if="courseList.length > 0" class="couserView_title">
      <img src="@/assets/img/vipxq/title1.png" alt="">
    </div>
    <el-collapse v-model="openChil" class="docList" accordion>
      <el-collapse-item v-for="(thisList, thisindex) in courseList" v-show="thisList.chapterNewVOS.length"
        :key="thisList.id" class="item" :name="thisList.id">
        <template slot="title">
          <div style="
              width: 228px;
              height: 128px;
              border-radius: 6px;
              overflow: hidden;
            ">
            <img :src="thisList.img" style="width: 100%" alt="">
          </div>
          <div class="docList-title" style="margin-left: 20px" @click.stop="seeDetails(thisList)">
            <div class="flex-c">
              <div class="docList-type shrink0">
                {{ liveEnum[thisList.type] }}
              </div>
              <div class="title-text ellipsis">
                {{ thisList.title
                }}<span v-if="thisList.type == 5" class="ellipsis" style="margin-left: 30px; width: 760px">
                  <el-tooltip class="item" effect="dark" :content="thisList.attendPlace" placement="bottom">
                    <el-button style="border: none; background: none">上课地点：{{ thisList.attendPlace }}<i
                        class="el-icon-location-information" /></el-button> </el-tooltip></span>
              </div>
            </div>
            <div class="tips flex-c">
              课程服务：随堂讲义 · 随堂资料 · 随堂测试 丨 共
              <span class="orange_text">{{
                thisList.chapterNewVOS.length
              }}</span>
              章
            </div>
            <div class="tea flex-c">
              <img src="" alt="">
              <span>讲师：{{}}</span><span style="color: #999; font-size: 14px">
                {{ thisList.ksTime ? thisList.ksTime : "0" }}
                课时</span>
            </div>
          </div>
          <!-- <div class="tips shrink0">

          </div> -->
        </template>
        <!-- 直播 -->
        <chater v-if="thisList.type == 1" :id="thisList.id" class="chater" :if-class="true" :class-id="classesId"
          :cc-config="thisList ? thisList.ccConfig : ''" :purchase="IsPurchase" :is-purchase="IsPurchase"
          :list="thisList.chapterNewVOS" :class-product-line="productLine" :card-code="cardCode" :is-freeze="isFreeze" />
        <!-- 录播 -->
        <video-chater v-if="thisList.type == 2" :id="thisList.id" class="video-chat" :if-class="true"
          :is-outsourcing="thisList.isOutsourcing" :class-id="classesId" :is-purchase="IsPurchase"
          :list="thisList.chapterNewVOS" :class-product-line="productLine" :card-code="cardCode" :is-freeze="isFreeze" />
        <!-- 音频 -->
        <audio-chat v-if="thisList.type == 3" :id="thisList.id" class="audio" :if-class="true" :class-id="classesId"
          :purchase="IsPurchase" :is-purchase="IsPurchase" :list="thisList.chapterNewVOS" :card-code="cardCode"
          :is-freeze="isFreeze" />
        <!-- 图文 -->
        <img-chat v-if="thisList.type == 4" :id="thisList.id" class="face" :if-class="true" :class-id="classesId"
          :purchase="IsPurchase" :is-purchase="IsPurchase" :list="thisList.chapterNewVOS" :card-code="cardCode"
          :is-freeze="isFreeze" />
        <!-- 面授 -->
        <face-chat v-if="thisList.type == 5" :id="thisList.id" class="face" :if-class="true" :class-id="classesId"
          :purchase="IsPurchase" :is-purchase="IsPurchase" :list="thisList.chapterNewVOS" :card-code="cardCode"
          :is-freeze="isFreeze" />
      </el-collapse-item>
    </el-collapse>
    <div v-if="!courseName || courseName.length == 0" class="nothing">
      <!-- <img class="nothing-img" src="@/assets/img/study/noLearn.png" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
  </div>
</template>
<script>
import { liveEnums } from '@/api/emun'
import { Know } from '@/api/know'
const know = new Know()
import { getInfo } from '@/api/cookies'
import chater from '@/components/Know/course/chaternew.vue'
import VideoChater from '@/components/Know/videoChaternew.vue'
import audioChat from '@/components/Know/course/audioChat.vue'
import faceChat from '@/components/Know/course/faceChatxbk.vue'
import imgChat from '@/components/Know/course/imgChat.vue'
export default {
  components: { VideoChater, chater, faceChat, audioChat, imgChat },
  props: [
    'classesId',
    'IsPurchase',
    'productLine',
    'cardCode',
    'isFreeze',
    'navIndex'
  ],
  data() {
    return {
      liveEnum: liveEnums,
      userInfo: null,
      list: {
        packages: [],
        paperList: []
      },
      openIndex: 0,
      openChil: 0,

      yearKc: [],
      currentYear: '',
      courseList: [],
      smallList: [],
      courseName: []
    }
  },
  created() {
    this.userInfo = getInfo()
    this.getList()
  },
  methods: {
    // 获取试卷包列表
    async getList() {
      await know
        .classesNewTableKsTime(
          this.classesId,
          this.userInfo && this.userInfo.id ? this.userInfo.id : undefined
        )
        .then((res) => {
          if (res.code == 0) {
            this.yearKc = res.data.yearKc
            this.currentYear = res.data.yearKc[0].year
            this.courseName = res.data.yearKc[0].yearKcNames
          }
        })
        .then(() => {

          this.collapseChange(0)
        })
      await know
        .getPrivateClassList(this.classesId, this.userInfo.id, this.currentYear)
        .then((res) => {
          if (res.code == 0) {
            this.smallList = res.data
          }
        })
    },
    /* 课程展开  */
    async collapseChange(val) {
      if (!val && val !== 0) return
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      const item = this.courseName[val]
      const data = []
      if (this.navIndex == 3) {
        await know
          .getPastPrivateClassList(
            this.classesId,
            this.userInfo ? this.userInfo.id : undefined
          )
          .then((res) => {
            if (res.code == 0) {
              this.smallList = res.data
            }
          })
      } else {
        await know
          .classesCourseTableList(
            this.classesId,
            this.userInfo ? this.userInfo.id : undefined,
            undefined,
            // this.currentYear,
            this.IsPurchase ? 1 : 0
          )
          .then((res) => {
            if (res.code == 0) {
              this.courseList = res.data
            }
          })
      }
      await know.getPrivateClassList(this.classesId).then((res) => {
        if (res.code == 0) {
          this.smallList = res.data
        }
      })
      loading.close()
    },

    changeYear(item) {
      this.courseName = item.yearKcNames
      this.currentYear = item.year
      this.collapseChange(0)
    },
    /* 点击查看详情 */
    seeDetails(item) {
      if (this.isFreeze == 1) {
        this.$message({
          type: 'error',
          message: '课程已冻结'
        })
        return
      }

      if (item.type == 1) {
        /* 直播 */
        const routeUrl = this.$router.resolve({
          path: `/zhiboclassxq?id=${item.id}&classId=${this.classesId}&classProductLine=3&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 2) {
        /* 录播 */
        const routeUrl = this.$router.resolve({
          path: `/videoxq?id=${item.id}&classId=${this.classesId}&classProductLine=3&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 3) {
        /* 音频 */
        const routeUrl = this.$router.resolve({
          path: `/audioCouser/index?id=${item.id}&classId=${this.classesId}&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 4) {
        /* 图文 */
        const routeUrl = this.$router.resolve({
          path: `/imgCouser/index?id=${item.id}&classId=${this.classesId}&cardCode=${this.cardCode}&vip=true`
        })
        window.open(routeUrl.href, '_blank')
      } else if (item.type == 5) {
        /* 面授 */
        const routeUrl = this.$router.resolve({
          path: `/faceCouser/index?id=${item.id}&classId=${this.classesId}&cardCode=${this.cardCode}`
        })
        window.open(routeUrl.href, '_blank')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.couserView {
  background: #f5f7f9;
  padding-bottom: 20px;
  padding-top: 20px;

  .year_wrap {
    min-height: 84px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 32px;
    padding-top: 28px;

    .year_item {
      width: 116px;
      background: #eeeff6;
      border-radius: 18px;
      height: 36px;
      font-size: 18px;
      font-family: MicrosoftYaHei;
      color: #666666;
      text-align: center;
      line-height: 36px;
      margin-right: 24px;
      margin-bottom: 24px;
      cursor: pointer;
    }

    .on {
      background: #ffece8;
      color: #ff5e51;
    }
  }

  .xiaoban {
    background-color: #fff;
  }

  .couserView_title {
    margin-top: 10px;
    margin-bottom: 0px;

    img {
      width: 236px;
      height: 50px;
      margin: 0 auto;
    }

    // width: 80px;
    // height: 20px;
    font-size: 20px;
    font-family: Source Han Sans CN-Medium,
    Source Han Sans CN;
    font-weight: 500;
    color: #ff5e51;
    line-height: 22px;
  }

  .couserView_title2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 90px;
    background-image: url("~@/assets/img/typeclass/titlexiao.png");
    background-repeat: no-repeat;
    background-size: 100% 90px;
    font-size: 22px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;

    // width: 80px;
    // height: 20px;
    span {
      &:first-child {
        // width: 108px;
        // height: 50px;
        font-size: 36px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #ffffff;
        margin-left: 30px;
        line-height: 90px;
      }
    }

    .line {
      display: inline-block;
      margin: 0 20px;
      width: 0px;
      height: 22px;
      opacity: 1;
      border: 1px solid #ffffff;
    }
  }
}

/deep/ .el-icon-arrow-right:before {
  display: none;
}

/deep/ .el-collapse-item__arrow {
  display: block;
  // background-color: #333;
  height: 32px;
  width: 100px;
  background-image: url("~@/assets/img/vipxq/cha.png");
  transform: rotate(0deg) !important;
  background-size: 100px 32px;
  background-repeat: no-repeat;
}

/deep/.el-collapse {
  border: none !important;

  .redRadio {
    display: none;
  }

  .is-active {
    .redRadio {
      display: inline-block;
    }
  }

  .el-icon-arrow-right {
    transform: rotate(90deg);
  }

  .is-active.el-icon-arrow-right {
    transform: rotate(270deg);
  }

  .el-collapse-item__header {
    width: 1200px;
    height: 50px;
    padding: 19px 25px 19px 0px;
    border: none !important;
    position: relative;
    margin: 0 auto;

    .title {
      display: flex;
      height: 20px;
      align-items: center;

      .title-name {
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: #222222;
        display: flex;
        align-items: center;
        margin-right: 20px;
        padding-left: 17px;

        .iconfont {
          color: #333333;
          font-size: 12px;
          margin-right: 9px;
        }

        img {
          width: 19px;
          height: 19px;
          margin-right: 18px;
        }
      }

      .shu {
        width: 4px;
        height: 20px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        margin-right: 29px;
      }
    }

    .fenge {
      // width: 816px;
      height: 1px;
      background: #eeeeee;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: absolute;
      bottom: 0px;
      left: 32px;
      right: 32px;
    }
  }

  .is-active {
    .shu {
      background: #ff5e51 !important;
    }

    .el-collapse-item__arrow {
      background-image: url("~@/assets/img/vipxq/shou.png") !important;
    }
  }

  .el-collapse-item__content {
    border: none !important;
    padding-bottom: 0px;
  }

  .el-collapse-item__wrap {
    border: none !important;
  }
}

/* 按章列表 */
/deep/ .docList {
  // padding: 0px 32px;
  .el-collapse {
    border: none !important;
  }

  .el-collapse-item__header {
    height: 168px;
    width: 1200px;
    background: #fbfbfb;
    border-radius: 4px;
    opacity: 1;
    padding: 20px 20px 20px 20px;
    margin-top: 8px;
  }

  .docList-type {
    // width: 45px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #ff7b1c;
    font-size: 11px;
    font-family: MicrosoftYaHei;
    color: #ff7b1c;
    background: #ffffff;
    // text-align: center;
    padding: 0px 6px;
    line-height: 18px;
    flex-shrink: 0;
    margin-right: 10px;
  }

  .docList-title {

    // padding: 20px 0px;
    /* display: flex;
    align-items: center;
    justify-content: space-between; */
    .flex-c,
    .title-text {
      // width: calc(100% - 120px);
      height: 42px;
    }

    .title-text,
    .title {
      font-size: 16px;
      line-height: 40px;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #e8662c;
    }

    .iconfont {
      font-size: 16px;
      width: 25px;
      height: 25px;
      transform: rotate(-90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .tips {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #666666;
    margin-right: 28px;
  }

  .open {
    .iconfont {
      transform: rotate(90deg);
      display: inline-block;
      transition: transform 0.4s;
    }
  }

  .uni-collapse-cell__title-text {
    border: none !important;
  }

  .uni-collapse-cell--open {
    .uni-collapse-cell__title-text {
      border: none !important;
    }
  }
}

/* 录播章节 音频 */
/deep/ .video-chat,
/deep/.audio {
  padding: 0px;

  // border-top: 1px solid #eee;
  .el-collapse-item__header {
    // width: 965px;
    margin: 0 auto;
    padding: 0px 20px !important;
    width: 1160px !important;
    height: 42px !important;
    line-height: 42px;
    border-left: none !important;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    background: #f8f8f8 !important;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
  }

  .el-collapse-item__content {
    .chat-item {
      margin: 0px 120px;
      background: none !important;
    }
  }
}

/deep/ .chater {
  // border-top: 1px solid #eee;
  // border-bottom: 1px solid #eee;
  .chat-item {
    padding: 0px 36px 0 93px;
  }
}

/* 试卷bao列表 */
.paperList {
  width: 100%;
  padding: 0px 32px 20px;

  .item-box {
    .item {
      width: 100%;
      display: flex;
      height: 264px;
      background: #ffffff;
      border-radius: 16px 16px 16px 16px;
      padding: 32px;
      margin-bottom: 24px;
      position: relative;

      .item-image {
        width: 200px;
        height: 200px;
        flex-shrink: 0;
        background: #f7f8f9;
        margin-right: 24px;
      }

      .item-content {
        width: 100%;

        .item-title {
          height: 88px;
          font-size: 28px;
          font-family: PingFang SC-Medium;
          font-weight: 500;
          color: #373a42;
          line-height: 44px;
        }

        .item-num {
          padding: 0px 12px;
          display: inline-block;
          height: 34px;
          background: #fff6ef;
          border-radius: 8px 8px 8px 8px;
          font-size: 22px;
          font-family: PingFang SC-Regular;
          font-weight: 400;
          color: #ff852d;
          line-height: 34px;
          margin-top: 8px;
        }

        .item-tips {
          margin-top: 34px;
          font-size: 24px;
          font-family: PingFang SC-Medium;
          line-height: 36px;
          font-weight: 500;
          color: #ff5d51;
          flex-shrink: 0;
        }
      }
    }
  }
}

/* 章节列表 */
.chat-list {
  padding: 0px;

  .chat-item {
    width: 100%;
    padding: 32px 0px;

    .item-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 300px);

      .title {
        font-size: 28px;
        font-family: PingFang SC-Medium;
        font-weight: 500;
        color: #373a42;
        line-height: 28px;
      }

      .time {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #9fa4ad;
        line-height: 24px;
        margin-top: 16px;
      }

      /* 直播 */
      .liveIng {
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ff5d51;
        line-height: 24px;

        .live {
          color: #ff5d51;
          font-size: 24px;
          margin-right: 8px;
        }
      }

      /* 回放 */
      .video {
        color: #6c7079;
        font-size: 40px;
      }
    }

    /* 未开始  回放生成中 */
    .noLive {
      color: #9fa4ad;

      .title,
      .time,
      .video {
        color: #9fa4ad !important;
      }
    }

    .but-list {
      flex-shrink: 0;
      display: flex;
      width: 240px;
      justify-content: flex-end;
      align-items: center;

      .keep-learn {
        margin-right: 12px;
        flex-shrink: 0;
        width: 72px;
        height: 24px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #ff5e51;
        padding: 0px;
        text-align: center;
        line-height: 24px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    /* .but-list {
      display: flex;
      margin-top: 48px;
      justify-content: flex-end;
      flex-shrink: 0;
      .item-but {
        margin-left: 24px;
        padding: 0px 24px;
        height: 48px;
        border-radius: 8px 8px 8px 8px;

        border: 2px solid #ff5d51;
        font-size: 24px;
        font-family: PingFang SC-Regular;
        font-weight: 400;
        color: #ff5d51;
        line-height: 48px;
        text-align: center;
        &:last-child {
          margin-right: 0px;
        }
      }
    } */
  }
}

.kcList {
  // width: 1136px;
  margin: 0 auto;
}

::v-deep .el-collapse-item__header {
  height: 58px;
  line-height: 58px;
  background: #f6f7f9;
  border-radius: 8px;
}

.el-collapse-item {
  margin-top: 8px;
}

.w-b-100 {
  // width: 90%;
}
</style>
